/* eslint max-len: 0 */
export const ICONS_ROUTE = '/icons';
export const SIGN_IN_ROUTE = '/signin';
export const SIGN_UP_ROUTE = '/signup';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const SELECT_WORKSPACE_ROUTE = '/select-workspace';
export const COMPLETE_PROFILE_ROUTE = '/complete-profile';
export const JOIN_WORKSPACE_ROUTE = '/join-customer';
export const EMAIL_ACTIONS_ROUTE = '/auth-action';
export const TERMS_ROUTE = '/terms';
export const PRIVACY_ROUTE = '/privacy';
export const CHOOSE_SUBSCRIPTION_PLAN_ROUTE = '/choose-plan';
export const UPDATE_SUBSCRIPTION_PLAN_ROUTE = '/update-plan';
export const PAYMENT_INFO_ROUTE = '/payment-information';
export const PAYMENT_AGREEMENT_ROUTE = '/payment-agreement';

export const DASHBOARD_ROUTE = '/';
export const INSIGHTS_ROUTE = '/insights';
export const ALERTS_RULES_ROUTE = '/alerts-rules';
export const ALERTS_HISTORY_ROUTE = '/alerts-history';
export const ALERT_CONTACTS_ROUTE = '/alert-contacts';

export const MOVE_MONEY_ROUTE = '/move-money';
export const MOVE_MONEY_CREATE_ROUTE = '/move-money/new';
export const MOVE_MONEY_BATCHES_ROUTE = '/move-money/batches';
export const MOVE_MONEY_ACTIVITY_ROUTE = '/move-money/activity';
export const MOVE_MONEY_SCHEDULED_TRANSACTIONS_ROUTE = '/move-money/scheduled-transactions';
export const MOVE_MONEY_MANAGE_RECIPIENTS_ROUTE = '/move-money/manage-recipients';
export const MOVE_MONEY_TRANSACTION_APPROVAL_ROUTE = '/move-money/transaction-approval';
export const TRANSACTION_APPROVAL_DETAILS_ROUTE = '/move-money/transaction-approval/:transactionId';
export const SCHEDULED_TRANSACTION_DETAILS_ROUTE = '/move-money/scheduled-transactions/:transactionId/:type';
export const RECIPIENT_DETAILS_ROUTE = '/move-money/manage-recipients/:recipientId';
export const RECIPIENT_TRANSACTION_DETAILS_ROUTE = '/move-money/manage-recipients/:recipientId/transactions/:transactionId/:type';
export const TRANSACTION_DETAILS_TYPE_ROUTE = '/move-money/transactions/:transactionId/:type';
export const SEARCH_TRANSACTIONS_ROUTE = '/transactions/search';

export const COMPANY_TEAM_MANAGEMENT_ROUTE = '/company/team-management';
export const COMPANY_USER_ROUTE = '/company/team-management/:userId';
export const COMPANY_PROFILE_ROUTE = '/company/profile';
export const COMPANY_BILLING_ROUTE = '/company/billing';

export const BANK_ACCOUNTS_TEMPORARY_ROUTE = '/bank-accounts/fiserv/:type';
export const BANK_ACCOUNTS_DETAILS_ROUTE = '/bank-accounts/:accountId';
export const QUICK_MOVE_MONEY_ROUTE = '/bank-accounts/:accountId/move-money';

export const USER_PROFILE_ROUTE = '/user/profile';
export const USER_SECURITY_ROUTE = '/user/security';
export const USER_PREFERENCES_ROUTE = '/user/preferences';

export const KYB_COVER_ROUTE = '/kyb';
export const KYB_CREATION_ROUTE = '/kyb/:step';
export const KYB_CREATION_REVIEW_ROUTE = '/kyb/review';

// admin portal
export const CUSTOMERS_ROUTE = '/customers';
export const CUSTOMER_DETAILS_ROUTE = '/customers/:customerId';
export const CUSTOMER_WORKSPACE_DETAILS_ROUTE = '/customers/:customerId/workspaces/:workspaceId';

export const SWAGGER_ROUTE = 'swagger';
