import {
  AuthorityType,
  BankAccountType,
  CustomerStatus,
  DefaultType,
  DocumentType,
  FinancialAccountSubtype,
  InvitationStatus,
  LimitType,
  MappedKybStatus,
  PermissionRoles,
  RecipientType,
  RuleHistoryStatus,
  TransactionStatusType,
  TransactionType,
  TransactionApprovalStatus, TransactionReversalReason,
} from 'constants/enums';
import { LabelsMappingInterface } from 'models/label.interface';

export const BANK_ACCOUNT_TYPES_AS_LABELS = {
  [DefaultType.all]: 'label.all',
  [BankAccountType.allTypes]: 'label.allTypes',
  [BankAccountType.saving]: 'label.saving',
  [BankAccountType.checking]: 'label.checking',
  [BankAccountType.credit]: 'label.credit',
  [BankAccountType.debit]: 'label.debit',
  [BankAccountType.prepaid]: 'label.prepaid',
  [BankAccountType.gift]: 'label.gift',
  [BankAccountType.dda]: 'label.dda',
  [BankAccountType.cca]: 'label.cca',
  [BankAccountType.inv]: 'label.inv',
  [BankAccountType.oaa]: 'label.oaa',
  [BankAccountType.ola]: 'label.ola',
  [BankAccountType.loc]: 'label.loc',
  [BankAccountType.equ]: 'label.equ',
  [BankAccountType.ila]: 'label.ila',
  [BankAccountType.ins]: 'label.ins',
  [BankAccountType.bpa]: 'label.bpa',
  [BankAccountType.sda]: 'label.sda',
  [BankAccountType.bnp]: 'label.bnp',
} as LabelsMappingInterface;

export const TRANSACTION_TYPES_AS_LABELS = {
  [TransactionType.credit]: 'label.credit',
  [TransactionType.debit]: 'label.debit',
  [TransactionType.cash]: 'label.cash',
  [TransactionType.request]: 'label.request',
  [TransactionType.send]: 'label.send',
} as LabelsMappingInterface;

export const PERMISSIONS_ROLES_AS_LABELS = {
  [PermissionRoles.admin]: 'label.admin',
  [PermissionRoles.clerk]: 'label.clerk',
  [PermissionRoles.finance]: 'label.finance',
  [PermissionRoles.manager]: 'label.manager',
  [PermissionRoles.owner]: 'label.owner',
  [PermissionRoles.viewOnly]: 'label.viewOnly',
} as LabelsMappingInterface;

export const AUTHORITY_AS_LABELS = {
  [AuthorityType.userAdmin]: 'label.admin',
  [AuthorityType.userOwner]: 'label.owner',
  [AuthorityType.userManager]: 'label.manager',
  [AuthorityType.userViewer]: 'label.viewer',
  [AuthorityType.opsAdmin]: 'label.admin',
  [AuthorityType.opsManager]: 'label.manager',
  [AuthorityType.opsViewer]: 'label.viewer',
} as LabelsMappingInterface;

export const INVITATION_STATUS_LABELS = {
  [InvitationStatus.accepted]: 'label.status.ACCEPTED',
  [InvitationStatus.visited]: 'label.status.VISITED',
  [InvitationStatus.new]: 'label.status.INVITED',
} as LabelsMappingInterface;

export const CUSTOMER_STATUS_LABELS = {
  [CustomerStatus.active]: 'label.status.ACTIVE',
  [CustomerStatus.inactive]: 'label.status.INACTIVE',
  [CustomerStatus.suspended]: 'label.status.SUSPENDED',
  [CustomerStatus.closed]: 'label.status.CLOSED',
} as LabelsMappingInterface;

export const FINANCIAL_ACCOUNT_SUBTYPES_LABELS = {
  [FinancialAccountSubtype.credit]: 'label.credit',
  [FinancialAccountSubtype.debit]: 'label.debit',
  [FinancialAccountSubtype.checking]: 'label.checkingAccount',
  [FinancialAccountSubtype.saving]: 'label.savingsAccount',
  [FinancialAccountSubtype.gift]: 'label.gift',
  [FinancialAccountSubtype.prepaid]: 'label.prepaid',
} as LabelsMappingInterface;

export const FINANCIAL_ACCOUNT_STATUSES_LABELS = {
  [TransactionStatusType.pending]: 'label.status.PENDING',
  [TransactionStatusType.new]: 'label.status.NEW',
  [TransactionStatusType.error]: 'label.status.ERROR',
  [TransactionStatusType.settled]: 'label.status.SETTLED',
  [TransactionStatusType.reversed]: 'label.status.REVERSED',
  [TransactionStatusType.processing]: 'label.status.PROCESSING',
  [TransactionStatusType.approved]: 'label.status.APPROVED',
  [TransactionStatusType.cancelled]: 'label.status.CANCELLED',
  [TransactionStatusType.cleared]: 'label.status.CLEARED',
  [TransactionStatusType.declined]: 'label.status.DECLINED',
  [TransactionStatusType.rejected]: 'label.status.REJECTED',
  [TransactionStatusType.active]: 'label.status.ACTIVE',
  [TransactionStatusType.recurrence]: 'label.status.RECURRENCE',
  [TransactionStatusType.finished]: 'label.status.FINISHED',
  [TransactionStatusType.scheduled]: 'label.status.SCHEDULED',
  [TransactionStatusType.deleted]: 'label.status.DELETED',
  [TransactionStatusType.suspended]: 'label.status.SUSPENDED',
  [TransactionStatusType.failed]: 'label.status.FAILED',
  [TransactionStatusType.depositWithheld]: 'label.status.DEPOSIT_WITHHELD',
} as LabelsMappingInterface;

export const RULE_HISTORY_STATUS_LABELS = {
  [RuleHistoryStatus.sent]: 'label.status.SENT',
  [RuleHistoryStatus.pending]: 'label.status.PENDING',
  [RuleHistoryStatus.delivered]: 'label.status.DELIVERED',
  [RuleHistoryStatus.failed]: 'label.status.FAILED',
  [RuleHistoryStatus.submitted]: 'label.status.SUBMITTED',
} as LabelsMappingInterface;

export const MAPPED_KYB_STATUS_LABELS = {
  [MappedKybStatus.draft]: 'label.status.IN_PROGRESS',
  [MappedKybStatus.inProgress]: 'label.status.IN_PROGRESS',
  [MappedKybStatus.inReview]: 'label.status.IN_REVIEW',
  [MappedKybStatus.accepted]: 'label.status.APPROVED',
  [MappedKybStatus.rejected]: 'label.status.REJECTED',
} as LabelsMappingInterface;

export const DOCUMENT_TYPE_LABELS = {
  [DocumentType.formation]: 'label.formation',
  [DocumentType.tax]: 'label.tax',
  [DocumentType.ownership]: 'label.businessOwnership',
  [DocumentType.passport]: 'label.passport',
  [DocumentType.driverLicense]: 'label.driverLicense',
} as LabelsMappingInterface;

export const INTERNAL_BANK_ACCOUNTS_SUBTYPE_LABELS = {
  [BankAccountType.checking]: 'label.checkingAccount',
  [BankAccountType.saving]: 'label.savingAccount',
} as LabelsMappingInterface;

export const LIMITS_LABELS = {
  [LimitType.single]: 'label.singleTransaction',
  [LimitType.daily]: 'label.cumulative24Hour',
  [LimitType.weekly]: 'label.cumulative7day',
  [LimitType.monthly]: 'label.cumulative30Day',
} as LabelsMappingInterface;

export const UTILIZATION_LIMITS_LABELS = {
  [LimitType.single]: 'label.single',
  [LimitType.daily]: 'label.24Hour',
  [LimitType.weekly]: 'label.7day',
  [LimitType.monthly]: 'label.30day',
} as LabelsMappingInterface;

export const RECIPIENT_TYPE_LABELS = {
  [RecipientType.business]: 'label.business',
  [RecipientType.individual]: 'label.individual',
} as LabelsMappingInterface;

export const REVIEW_TRANSACTION_APPROVAL_STATUS_LABELS = {
  [TransactionApprovalStatus.approved]: 'label.approve',
  [TransactionApprovalStatus.rejected]: 'label.reject',
} as LabelsMappingInterface;

export const REVERSAL_REASON_LABELS = {
  [TransactionReversalReason.duplicatePayment]: 'label.duplicatePayment',
  [TransactionReversalReason.incorrectRecipient]: 'label.incorrectRecipient',
  [TransactionReversalReason.incorrectAmount]: 'label.incorrectAmount',
} as LabelsMappingInterface;
