
const customerPalette = {
  primary: {
    main: '#24605D',
    secondary: '#1F7984',
    dark: '#122F2E',
  },
  secondary: {
    main: '#D9EFF1',
    secondary: '#89A5A4',
    dark: '#A7BFBE',
  },
  error: {
    main: '#FF6E6E',
    secondary: '#FF6E6E',
  },
  success: {
    main: '#43CA21',
    secondary: '#B4F89D',
  },
  warning: {
    main: '#ff7600',
    secondary: '#FFB257',
  },
  background: {
    main: '#f7f7f7',
    secondary: '#fff',
  },
  border: {
    main: '#757575',
    secondary: '#E2E3E4',
  },
  text: {
    primary: '#111111',
    secondary: '#4A4A4A',
    disabled: '#BABABA',
  },
  general: {
    lightBlack: '#111111',
    lightGrey: '#d7d7d7',
    lightGrey2: '#F7F7F7',
    lightGrey3: '#E2E3E4',
    lightGrey4: '#EDEDED',
    lightGrey5: '#D9EFF1',
    lightGrey6: '#BABABA',
    lightGrey7: '#F6F6F6',
    lightGrey8: '#F9F9F9',
    lightGrey9: '#EBEBEB',
    lightGrey10: '#E7EAEA',
    lightGrey11: '#CBD4D4',
    lightGrey12: '#EDEDED',
    darkGrey: '#4A4A4A',
    darkGrey1: '#757575',
    lightYellow: '#FFF4D0',
    lightYellow1: '#FFF0DD',
    lightYellow2: '#FFD187',
    lightYellow3: '#F7EAC0',
    lightYellow4: '#EEE2B6',
    darkYellow: '#FBC710',
    darkOrange: '#DE7800',
    darkOrange1: '#FF8A24',
    lightGreen1: '#B4F89D',
    lightGreen3: '#229C03',
    lightGreen4: '#DAE4E4',
    lightGreen5: '#A7BFBE',
    darkGreen: '#0E2625',
    darkGreen2: '#265451',
    darkGreen3: '#24605d',
    darkGreen4: '#315856',
    lightRed: '#FF9898',
    lightRed1: '#F16868',
    blue: '#0085FF',
    lightBlue1: '#D2EBFA',
  },
  common: {
    black: '#000',
    white: '#fff',
  },
};

export default customerPalette;
