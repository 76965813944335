import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from 'hooks/useRedux';
import { updateLoggedUser } from 'services/UserService';
import {
  createWorkspace,
  generateJwtToken,
  getLoggedUserWorkspaces,
  updateCustomerBankAccountsInBackground,
} from 'services/WorkspaceService';
import { resetStore } from 'store/actions';
import { loginWorkspacesSelector } from 'store/user/selectors';
import { setCurrentWorkspace, setJwtToken, setUserWorkspaces } from 'store/user/userSlice';

import { CHOOSE_SUBSCRIPTION_PLAN_ROUTE, DASHBOARD_ROUTE, KYB_COVER_ROUTE } from 'constants/clientRoutes';
import { User } from 'models/user.interface';
import { existingFlags, isFeatureEnabled } from 'utils/featureFlag';
import { formatFbUserToInAppUser } from 'utils/formatters';
import { WorkspaceFormValues } from 'utils/validation/workspaceFormSchema';
import { formatWorkspaceName } from 'utils/workspace';

export const useWorkspaceAuth = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const workspaces = useAppSelector(loginWorkspacesSelector);

  const loginWorkspace = async (workspaceId: string, route?: string) => {
    const { data: token } = await generateJwtToken(workspaceId);
    dispatch(setJwtToken(token));
    updateCustomerBankAccountsInBackground(workspaceId);

    const auth = getAuth();
    const fbUser = auth.currentUser;

    const inAppUser = formatFbUserToInAppUser(fbUser);
    await updateLoggedUser(inAppUser);

    navigate(route || DASHBOARD_ROUTE);
  };

  const switchWorkspace = async (workspaceId: string, formattedUser?: Partial<User>) => {
    const { data: token } = await generateJwtToken(workspaceId);
    await dispatch(resetStore());
    dispatch(setJwtToken(token));
    updateCustomerBankAccountsInBackground(workspaceId);

    if (formattedUser) {
      await updateLoggedUser(formattedUser);
    } else {
      const auth = getAuth();
      const fbUser = auth.currentUser;

      const inAppUser = formatFbUserToInAppUser(fbUser);
      await updateLoggedUser(inAppUser);
    }
  };

  const createAndSwitchWorkspace = async (
    values: WorkspaceFormValues,
    onSuccess: (newWorkspace: WorkspaceFormValues) => void,
    onFail: (error: any) => void,
  ) => {
    try {
      const displayName = values.name ? formatWorkspaceName(values.name) : null;
      const { data: newWorkspace } = await createWorkspace({
        ...values,
        displayName,
      });
      const { data: refreshedWorkspaces } = await getLoggedUserWorkspaces();
      dispatch(setUserWorkspaces(refreshedWorkspaces?.content || []));
      dispatch(setCurrentWorkspace(null));

      await switchWorkspace(newWorkspace.id);
      dispatch(resetStore());

      onSuccess(newWorkspace);
    } catch (error: any) {
      onFail(error);
    }
  };

  const createAndLoginWorkspace = async (
    values: WorkspaceFormValues,
    onSuccess: (newWorkspace: WorkspaceFormValues) => void,
    onFail: (error: any) => void,
  ) => {
    try {
      const displayName = values.name ? formatWorkspaceName(values.name) : null;
      const { data: newWorkspace } = await createWorkspace({
        ...values,
        displayName,
      });
      const redirectRoute = isFeatureEnabled(existingFlags.subscriptions)
        ? CHOOSE_SUBSCRIPTION_PLAN_ROUTE
        : KYB_COVER_ROUTE;
      await loginWorkspace(newWorkspace.id, redirectRoute);
      dispatch(setUserWorkspaces([...workspaces, newWorkspace]));

      onSuccess(newWorkspace);
    } catch (error: any) {
      onFail(error);
    }
  };

  return {
    loginWorkspace,
    switchWorkspace,
    createAndSwitchWorkspace,
    createAndLoginWorkspace,
  };
};
