import {
  Box,
  Divider, Paper, Skeleton, Typography,
} from '@mui/material';
import {
  FC, ReactElement, useEffect, useMemo, useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import BannerMessage from 'components/BannerMessage';
import BusinessDetails from 'components/BusinessDetails';
import CompanyBeneficiary from 'components/CompanyBeneficiary';
import CustomButton from 'components/CustomButton';
import EditHeaderWrapper from 'components/EditHeaderWrapper';
import Logo from 'components/Logo';
import {
  Layout, CardMedia, Navbar, Content, Title,
} from 'modules/kyb/containers/ReviewKyb/index.styled';

import { usePermissions } from 'hooks/usePermissions';
import { useAppSelector, useAppDispatch } from 'hooks/useRedux';
import { submitKybApplication } from 'services/KybService';
import { fetchIdentityApplication, fetchKybDetails } from 'store/kyb/kybSlice';
import {
  companyDetailsSelector,
  documentsSelector,
  isLoadingCompanyDetailsSelector,
  isLoadingKybDetailsSelector,
  kybDetailsSelector,
} from 'store/kyb/selectors';
import { currentWorkspaceSelector } from 'store/user/selectors';
import { fetchWorkspaceDetails } from 'store/user/userSlice';

import BeneficiaryIcon from 'assets/icons/BeneficiaryIcon';
import CompanyIcon from 'assets/icons/CompanyIcon';
import AuthLayoutBackground from 'assets/images/auth-bg.svg';
import { DASHBOARD_ROUTE, KYB_CREATION_ROUTE } from 'constants/clientRoutes';
import { KybSteps } from 'constants/enums';
import { RolePermissions } from 'constants/permissionEnums';
import { handleApiErrors } from 'utils/errorUtils';
import { formatRoute } from 'utils/formatters';
import { checkMissingInfo } from 'utils/kyb';

const ReviewKyb:FC = (): ReactElement => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAllowed } = usePermissions();
  const documents = useAppSelector(documentsSelector);
  const companyDetails = useAppSelector(companyDetailsSelector);
  const isLoadingCompanyDetails = useAppSelector(isLoadingCompanyDetailsSelector);
  const kybDetails = useAppSelector(kybDetailsSelector);
  const isLoading = useAppSelector(isLoadingKybDetailsSelector);
  const customer = useAppSelector(currentWorkspaceSelector);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const canManageKYB = isAllowed({ permission: RolePermissions.ManageKybDetails });
  const missingInfo = useMemo(() => {
    if (isLoading) {
      return { business: [], beneficiary: false };
    }

    return checkMissingInfo(kybDetails);
  }, [kybDetails, isLoading]);
  const hasMissingInformation = Boolean(missingInfo?.business?.length || missingInfo.beneficiary);

  useEffect(() => {
    if (customer?.id) {
      dispatch(fetchKybDetails(customer?.id));
    }
  }, [customer?.id]);

  const handleEditBusinessProfile = () => {
    navigate(formatRoute(KYB_CREATION_ROUTE, { step: KybSteps.companyDetails }));
  };

  const handleEditBeneficiaries = () => {
    navigate(formatRoute(KYB_CREATION_ROUTE, { step: KybSteps.beneficiaries }));
  };

  const handleSaveAndFinishLater = () => {
    navigate(DASHBOARD_ROUTE);
  };

  const handleSubmitKybApplication = async () => {
    try {
      if (!customer?.id) {
        toast.error(intl.formatMessage({ id: 'error.unknownWorkspace' }));
        return;
      }

      setIsSubmitting(true);
      await submitKybApplication(customer?.id);
      await Promise.all([
        dispatch(fetchIdentityApplication(customer?.id)),
        dispatch(fetchWorkspaceDetails()),
      ]);
      navigate(DASHBOARD_ROUTE);
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderSkeleton = () => (
    <Skeleton
      animation="wave"
      variant="rectangular"
      height={400}
      width="100%"
    />
  );

  return (
    <Layout>
      <CardMedia
        component="img"
        src={AuthLayoutBackground}
        alt="Auth Layout Background"
      />
      <Navbar>
        <Logo />
      </Navbar>

      <Content maxWidth="sm">
        <Title>
          {intl.formatMessage({ id: 'label.reviewYourCompanyProfile' })}
        </Title>
        <Typography variant="subtitle1" mb={7}>
          {intl.formatMessage({ id: 'label.reviewYourCompanyProfileDescription' })}
        </Typography>

        {hasMissingInformation && (
          <BannerMessage
            message={intl.formatMessage({ id: 'label.missingInformation' })}
            sx={{ flexDirection: 'column', mb: 5, alignItems: 'flex-start' }}
          >
            <ul>
              {missingInfo.business?.length > 0 && (
                <li>
                  <Typography>
                    {intl.formatMessage({ id: 'label.missingKybBusinessInfo' })}
                    &nbsp;
                    {missingInfo.business
                      .map((info) => intl.formatMessage({ id: info }))
                      .join(', ')}
                  </Typography>
                </li>
              )}
              {missingInfo.beneficiary && (
                <li>
                  <Typography>
                    {intl.formatMessage({ id: 'label.missingBeneficiary' })}
                  </Typography>
                </li>
              )}
            </ul>
          </BannerMessage>
        )}
        <Paper elevation={0} className="large" sx={{ mb: 5 }}>
          <EditHeaderWrapper
            onClick={handleEditBusinessProfile}
            sx={{ mb: 4 }}
            canEdit
          >
            <Box display="flex" alignItems="center" gap={2}>
              <CompanyIcon size={20} />
              <Typography variant="body2" fontWeight={600}>
                {intl.formatMessage({ id: 'label.businessProfile' })}
              </Typography>
            </Box>
          </EditHeaderWrapper>

          {isLoadingCompanyDetails
            ? renderSkeleton()
            : (
              <BusinessDetails
                companyDetails={companyDetails}
                documents={documents}
                detailed
              />
            )}
        </Paper>

        <Paper elevation={0} className="large">
          <EditHeaderWrapper
            onClick={handleEditBeneficiaries}
            sx={{ mb: kybDetails.beneficiaries?.length ? 4 : 0 }}
            canEdit
          >
            <Box display="flex" alignItems="center" gap={2}>
              <BeneficiaryIcon size={20} />
              <Typography variant="body2" fontWeight={600}>
                {intl.formatMessage({ id: 'label.companyBeneficiaryAndControllerProfile' })}
              </Typography>
            </Box>
          </EditHeaderWrapper>

          {isLoading
            ? renderSkeleton()
            : (
              kybDetails.beneficiaries?.map(({ beneficiary, documents: beneficiaryDocuments }, index) => (
                <div key={beneficiary.id}>
                  <CompanyBeneficiary
                    beneficiary={beneficiary}
                    documents={beneficiaryDocuments}
                    detailed
                  />

                  {index + 1 < kybDetails?.beneficiaries?.length && (
                    <Divider sx={{ my: 5, color: 'general.lightGrey3' }} />
                  )}
                </div>
              ))
            )}
        </Paper>
        <Box display="flex" gap={2} justifyContent="flex-end" my={7}>
          <CustomButton
            id="buton-saveAndFinishLater"
            label={intl.formatMessage({ id: 'button.saveAndFinishLater' })}
            variant="text"
            className="finishLater"
            onClick={handleSaveAndFinishLater}
          />
          {canManageKYB && (
            <CustomButton
              id="buton-confirmAndSubmit"
              onClick={handleSubmitKybApplication}
              label={intl.formatMessage({ id: 'button.saveAndSubmit' })}
              isLoading={isSubmitting || isLoading || isLoadingCompanyDetails}
              disabled={hasMissingInformation}
            />
          )}
        </Box>
      </Content>
    </Layout>
  );
};

export default ReviewKyb;
